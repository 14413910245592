.homepage-third-block-div {
    margin: clamp(40px, 4vw, 60px) auto;
    display: flex;
    flex-direction: column;
}

.homepage-third-first-row {
    max-width: 1500px;
    width: 80%;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: clamp(40px,4vw,60px) auto;
    flex-flow: row;
}

.dashboard-div {
    align-items: center;
    background-color: var(--black);
    border-radius: 2.5vw;
    box-shadow: 20px 20px 40px #00000033;
    display: flex;
    height: fit-content;
    width: fit-content;
    padding: 1vw;
    align-items: center;
    justify-content: center;
}

.dashboard-img {
    background-color: var(--black-coral-pearl);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 1.6vw;
    box-shadow: 6px 6px 24px #0000000d;
    height: 24vw;
    width: 40vw;
    max-width: 900px;
    filter: sepia(100%) hue-rotate(190deg) saturate(150%);
}

.homepage-third-dashboard-text {
    align-items: flex-start;
    display: flex;
    width:40vw;
    max-width: 600px;
    flex-direction: column;
}

.homepage-third-dashboard-text-first-sentence {
    line-height: 2vw;
    width: 90%;
}

.homepage-third-reasons-why {
    display: flex;
    justify-content: center;
}

.tablet-mobile-third {
    display: none;
}

.margin-top-sentence {
    margin-top: max(3vw, 20px);
  }

@media screen and (max-width: 1023px) {

    .homepage-third-first-row {
        width: 90%;
    }

    .homepage-third-dashboard-text-first-sentence {
        line-height:2.5vw;
    }

    .dashboard-div {
        padding:1.5vw;
    }


    .dashboard-img {
        height: 28vw;
        width: 46vw;
    }

}

@media screen and (max-width: 800px) {

    .tablet-mobile-third {
        display: flex;
    }

    .dashboard-div {
        border-radius: 3vw;
    }

    .dashboard-img {
        height: 24vw;
        width: 42vw;
    }

    .homepage-third-dashboard-text-first-sentence {
        width:95%
    }

}

@media screen and (max-width: 675px) {
    .homepage-third-first-row {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .homepage-third-dashboard-text-first-sentence {
        line-height:4vw;
    }

    .homepage-third-dashboard-text {
        margin-top: 8vw;
        width: max(400px, 75%);
        align-items: center;
    }

    .dashboard-div {
        padding:2vw;
        border-radius: 3vw;
    }

    .dashboard-img {
        height: 40vw;
        width: 68vw;
    }

}

@media screen and (max-width: 475px) {

    .homepage-third-dashboard-text {
        width: 100%;
    }

    .homepage-third-dashboard-text-first-sentence {
        line-height:20px;
    }
}