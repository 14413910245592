@import "../../assets/styles/global.scss";

.container-footer {
  background-color: var(--black);
  border-radius: 0;
  display: block;
  margin: 0 ;
  justify-content: center;
  height: fit-content;
}

.main-content {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: 22vw;
  grid-column-gap: 5%;
  justify-content: center;
  padding: clamp(45px,4.5vw,90px) clamp(40px, 4vw, 80px) 0;
  height: max(13vw, 200px);
  max-width: 1500px;
  margin: 0 auto;
}

.main-content-col {
  text-align: center;
  justify-content: center;
  display: grid;
  height: fit-content;
  grid-template-rows: clamp(40px,4vw,80px) auto;
}

.copyright {
  opacity: 0.6;
  white-space: nowrap;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.lynxai-logo-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#join-newsletter-form {
  transition: all 1s;
}

#join-newsletter-form.fade-out {
  opacity: 0;
  visibility: hidden;
}

.connection {
  justify-content: center;
  display: flex;
  height: fit-content;
}

.privacy-policy-div {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  height: clamp(60px, 6vw,100px);
  max-width: 1500px;
  margin: 0 auto;
}

.lynxai-logo {
  height: clamp(20px,2vw, 40px);
  object-fit: cover;
}

.div-join-newsletter {
  display: flex;
  height: clamp(30px,2.5vw,50px);
  width: clamp(152px,15.2vw, 304px);
}

.input-email {
  border: 1px solid;
  border-color: var(--white);
  border-radius: 32px;
  background: transparent;
  height: auto;
  opacity: 0.7;
  color: white;
  padding: 0 clamp(12px,1.2vw,24px);
  width: clamp(100px, 10vw,200px);
}

#send-icon-div {
  width: clamp(30px,2.5vw,50px);
  height: clamp(30px,2.5vw,50px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: clamp(2px, 0.2vw, 4px);
  background-color: white;
  border: none !important;
  transition: visibility 1s, opacity 1s;
}

#send-icon-div.fade-out {
  opacity: 0;
  visibility: hidden;
}

.div-join-newsletter form {
  display: flex;
}

.send-icon {
  content:url(../../assets/images/send_icon_black.png);
  height:65%;  
  margin: 0 0 max(-0.2vw,-2px) max(-0.2vw,-2px);
  transition: all 0.5s;
}

#small-loader-gif {
  margin: auto 0 auto clamp(2px, 0.2vw, 4px);
  visibility:hidden;
  opacity: 0;
  transition: all 1s;
  height: clamp(13px, 1.3vw, 26px);
}

#small-loader-gif.fade-in {
  opacity: 1;
  visibility: visible;
}

#join-newsletter-done {
  transition: all 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

#join-newsletter-done.fade-in {
  opacity: 1;
  visibility: visible;
}

#send-icon-div:hover {
  background-color: black;
}

#send-icon-div:hover .send-icon {
  height:75%;
  margin: 0 0 max(-0.4vw,-4px) max(-0.4vw,-4px);
  content:url(../../assets/images/send_icon_white.png);
}

.connection-name {
  line-height: clamp(30px,2.5vw,50px);
  margin-left: 8px;
  text-align: left;
  opacity: 0.7;
}

.privacy-and-terms {
  height: 100%;
  align-items: center;
  opacity: 0.6;
  display: flex;
  justify-content: center;
}

.text-divider {
  margin: 0 max(10px, 1vw);
}

.connection-icon-div {
  width: clamp(30px,2.5vw,50px);
  height: clamp(30px,2.5vw,50px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: white;
  transition: all 0.5s;
}

.linkedin-icon {
  content:url(../../assets/images/linkedin-logo-black.png);
  height:65%;  
}

.email-icon {
  content:url(../../assets/images/email-icon-black.png);
  height:65%;  
}

.email-div:hover .connection-icon-div {
  background-color: black;
}

.email-div:hover .email-icon {
  height:75%;
  content:url(../../assets/images/email-icon-white.png);
}

.linkedin-div:hover .connection-icon-div {
  background-color: black;
}

.linkedin-div:hover .linkedin-icon {
  height:75%;
  content:url(../../assets/images/linkedin-logo-white.png);
}


@media (max-width: 1023px) {
  .main-content {
    grid-template-columns: 23% 23% 23% 23%;
    grid-column-gap: 2%;
  }
}

@media (max-width: 850px) {
  .main-content {
    grid-template-columns: 45% 45%;
    grid-template-rows: max(17vw,120px) max(17vw,120px);
    grid-column-gap: clamp(30px,4vw,60px);
    grid-row-gap: clamp(50px,5vw,100px);
    height: max(40vw, 260px);
  }
}

@media (max-width: 600px) {

  .main-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max(18px, 100px) max(18px, 100px) max(18px, 100px) max(18px, 100px);
    grid-row-gap: clamp(20px, 2vw,40px);
    justify-content: center;
    align-content: center;
    padding: max(4vw, 30px) max(15vw, 40px) 0 max(15vw, 40px);
    height: max(95vw, 530px);
  }

  .main-content-col {
    text-align: left;
    justify-content: flex-start;
  }

  .connection {
    justify-content: flex-start;
  }

  .privacy-policy-div {
    grid-template-columns: 100%;
    grid-template-rows: 40px 25px 25px;
    padding: 0 max(15vw, 40px);
    height: clamp(125px,12.5vw, 250px);
  }

  .copyright {
    justify-content: flex-start;
  }

  .lynxai-logo-div {
    grid-row-start: 1;
    grid-row-end: 1;
    justify-content: flex-start;
  }

  .privacy-and-terms {
    justify-content: flex-start;
  }

}

@media (max-width: 375px) {
  .privacy-policy-div {
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 110px;
    padding-bottom: 20px
  }

  .main-content {
    padding: 20px 20px 0 20px;
  }

  .main-content-col {
    text-align: center;
    justify-content: center;
  }
}