@import "../../assets/styles/global.scss";

.pai-fourth-block-div {
  margin: clamp(50px, 5vw, 100px) auto;
  background-color: var(--black);
  border-radius: 5vw 0px 5vw 0px;
  display: flex;
  flex-direction: column;
  padding: clamp(60px, 6vw, 120px);
  justify-content: center;
    align-items: center;
  max-width: 1500px;
}

.eu-taxonomy-div {
  width: 100%;
}

.eu-taxonomy-inside-div {
  display: flex;
  width: 100%;
  margin-top: 1.5vw;
}

.pai-fourth-info-div {
  width: 65%;
  display: block;
  height: fit-content
}

.pai-fourth-image-div {
  width: 35%;
  position: relative;
}

.eu-taxonomy-div .pai-fourth-image-div {
  text-align: right;
  padding: clamp(20px,2vw,40px) 0 0 clamp(50px, 5vw, 100px);
}

.pai-fourth-title {
  text-align: left;
  line-height: clamp(25px, 1vw, 40px);
}

.image-diagram {
  height: auto;
  background-size: scale;
  width: 100%;
}

.pai-fourth-fifth-point-desktop {
    display:flex;
}

.image-diagram-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.pai-fourth-icons-div-desktop {
  display: grid;
  height: fit-content;
  width: 100%;
  grid-template-columns: 45% 45%;
  grid-template-rows: fit-content fit-content;
  grid-column-gap: 3vw;
  grid-row-gap: 2vw;
  margin: clamp(30px, 3vw, 60px) 0 2vw 0;
}

.pai-fourth-icons-div-mobile {
  display: none;
  height: fit-content;
  margin: clamp(35px, 3.5vw, 70px) auto clamp(60px, 6vw, 120px) clamp(-60px, -6vw, -120px);
  width: calc(100vw - 40px);
}

.pai-fourth-points {
  display: flex;
  flex-flow: column;
  border-top: 2px solid var(--gray-nurse-2);
  padding: clamp(10px, 1vw,20px) clamp(20px, 2vw, 40px) 0 0;
}

.pai-fourth-icon {
  height: auto;
  object-fit: cover;
  width: clamp(25px, 2.5vw,50px);
}

.pai-fourth-main-text-desktop {
  margin-top: clamp(10px, 1vw, 20px);
  line-height: clamp(14px, 1.4vw, 28px);
}

.pai-fourth-text-desktop {
  margin-top: clamp(10px, 1vw, 20px);
  line-height: clamp(12px, 1.2vw, 24px);
}

.button-div {
  margin-top: clamp(30px, 3vw,60px)
}

.pai-fourth-main-title-desktop {
  display: block;
  line-height: clamp(25px, 2.5vw, 50px);
}

.pai-fourth-main-title-mobile {
  display: none;
}

@media screen and (max-width: 750px) {

  .eu-taxonomy-inside-div {
    flex-flow: column;
    flex-direction: column-reverse;
  }

.pai-fourth-fifth-point-desktop {
    display: none;
}

  .pai-fourth-image-div {
    width: 100%;
    padding: 0;
  }

  .eu-taxonomy-div .pai-fourth-image-div {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }

  .image-diagram {
    width: 55vw;
  }

  .pai-fourth-info-div {
    width: 100%
  }

  .pai-fourth-block-div {
    border-radius: 0px 8vw 0px 8vw;
  }

  .pai-fourth-main-title-desktop {
    display: none;
  }

  .pai-fourth-main-title-mobile {
    display: block;
  }

  .pai-fourth-icons-div-desktop {
    display: none
  }

  .pai-fourth-icons-div-mobile {
    display: block;
  }
}