.round-corners-main-image-block-div {
    border-radius: 5vw 0px 5vw 0px;
    height: clamp(550px, 50vw,1000px);
    margin: clamp(20px,3vw,60px) auto;
    max-width: 1600px;
    position: relative;
  }
  
  .round-corners-main-image-block-img {
    border-radius: 5vw 0px 5vw 0px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .round-corners-main-image-block-darker {
    background-color: var(--cod-gray);
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    border-radius: 5vw 0px 5vw 0px;
    width: 100%;
  }
  
  .round-corners-main-image-block-div .lynxai-logo {
    height: clamp(30px,3vw,60px);
    object-fit: contain;
  }
  
  @media screen and (max-width: 750px) {
  
    .round-corners-main-image-block-img {
      border-radius: 8vw 0px 8vw 0px;
      object-position: 50% 0;
    }
  
    .round-corners-main-image-block-darker {
      border-radius: 8vw 0px 8vw 0px;
    }
  
  }
  