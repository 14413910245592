.mentor-details {
    display: flex;
    flex-flow: row;
    width: clamp(325px, 30vw, 550px);
    margin: clamp(12px, 1.2vw, 24px) clamp(16px, 1.6vw, 32px);
    background-color: var(--polar);
    border-radius: clamp(6px, 2vw, 12px);
    padding: clamp(20px, 2vw, 30px);
    height: clamp(200px, 19vw, 350px);
}

.mentor-img {
    width: clamp(150px, 15vw, 280px);
    height: clamp(180px, 18vw, 320px);
    object-fit: cover;
    border-radius: 1vw;
    margin: auto 0;
}

.mentor-text-div {
    padding: 10px 0 0 25px;
    height: 100%;
}

.mentor-name-linkedin {
    display: flex;
    align-items: center;
}

.about-us-fifth-linkedin-button {
    background-color: var(--live-blue);
    width: clamp(20px, 1.8vw, 30px);
    height: clamp(20px, 1.8vw, 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
}

.about-us-fifth-linkedin-image {
    width: clamp(18px, 1.5vw, 25px);
    height: clamp(18px, 1.5vw, 25px);
}

.about-us-fifth-mentor-experience {
    margin: clamp(6px, 0.6vw, 12px) 0;
}

.about-us-fifth-mentor-location {
    font-style: italic;
}

.about-us-fifth-experience-text {
    line-height: clamp(12px, 1.2vw, 24px);
}

.about-us-fifth-experience-main-div {
    margin-top: clamp(20px, 3vw, 30px);
}

.about-us-fifth-experience-div {
    display: flex;
    align-items: top;
    margin: 9px 0;
}

.about-us-fifth-experience-bullet {
    width: clamp(14px,1.4vw, 24px);
    height: clamp(14px,1.4vw, 24px);
    margin-right: 5px;
}

@media screen and (max-width: 1600px) {
    .mentor-details {
        width: clamp(400px, 40vw, 550px);
    }
}

@media screen and (max-width: 1000px) {
    .mentor-details {
        width: clamp(300px, 39vw, 550px);
        height: clamp(200px, 20vw, 350px)
    }
}

@media screen and (max-width: 900px) {
    .mentor-details {
        width: clamp(300px, 39vw, 550px);
        height: clamp(225px, 24vw, 350px)
    }
}

@media screen and (max-width: 650px) {
    .mentor-details {
        flex-flow: column;
        width: fit-content;
    }

    .mentor-img {
        width: 100%;
        height: 250px;
    }

    .mentor-text-div {
        padding-left: 0;
    }

    .about-us-fifth-experience-main-div {
        margin-top: clamp(5px, 0.5vw, 10px);
    }
}