.about-us-second-div {
    height:max(15vw,130px);
    margin: max(5vw,50px) auto;
    padding:2vw 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: clamp(300px,40vw,1100px);
}

.about-us-second-icon {
    height:clamp(25px, 2.5vw,50px);
}

.about-us-second-text {
    text-align: center;
}