.regulatory-compliance-div {
    margin: clamp(80px, 8vw, 160px) auto clamp(50px, 5vw, 100px);
    width: 85vw;
    max-width: 1500px;
}

.regulatory-compliance-inside-div {
    display: flex;
    margin-top: 1.5vw;
}

.regulatory-compliance-image-div {
    position: relative;
    text-align: right;
    margin-right: clamp(20px, 2vw, 40px);
    width: clamp(250px, 25vw, 500px);
    max-width: 600px;
}

.regulatory-compliance-div .image-diagram {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
}

.lynxai-rcr-second-icon {
    height: auto;
    object-fit: cover;
    width: clamp(20px, 2vw, 40px);
    margin-bottom: clamp(3px, 0.3vw, 9px)
}

.lynxai-rcr-second-points {
    border-top: 2px solid var(--cape-cod);
    display: flex;
    flex-flow: column;
    padding: clamp(14px, 1.4vw, 30px) clamp(10px, 1vw, 25px) 0 0;
}

.regulatory-compliance-info-div {
    display: block;
    width: 100%;
    height: fit-content;
}

.lynxai-rcr-second-image-1 {
    height: auto;
    border-radius: clamp(12px, 1.2vw, 24px);
    object-fit: cover;
    width: clamp(200px, 25vw, 500px);
}

.lynxai-rcr-second-image-2 {
    border-radius: clamp(12px, 1.2vw, 24px);
    object-fit: cover;
    width: clamp(180px, 18vw, 320px);
    height: auto;
    margin-top: clamp(20px, 2vw, 40px);
}

.lynxai-rcr-second-icons-div-mobile {
    display: none;
    width: 100%;
    height: fit-content;
}

.lynxai-rcr-try-demo-div {
    width: 100%;
    margin: clamp(30px, 3vw, 60px) auto 0 auto;
}

.lynxai-rcr-second-title {
    width: 50vw;
    text-align: left;
    line-height: clamp(25px, 4vw, 60px);
}

.lynxai-rcr-second-title-desktop {
    display: block;
}

.lynxai-rcr-second-title-mobile {
    display: none;
}

.lynxai-rcr-second-text {
    line-height: clamp(15px, 1.5vw, 30px);
    margin-top: clamp(7px, 0.7vw, 14px);
}

.lynxai-rcr-second-icons-div-desktop {
    display: grid;
    height: fit-content;
    width: 100%;
    max-width: 950px;
    grid-template-columns: clamp(200px, 28vw, 460px) clamp(200px, 28vw, 460px);
    grid-template-rows: clamp(150px, 12vw, 250px) clamp(150px, 12vw, 250px);
    grid-column-gap: clamp(20px, 2vw, 30px);
    grid-row-gap: clamp(20px, 2vw, 35px);
    margin-top: clamp(24px, 2.4vw, 40px);
    padding-bottom: clamp(15px, 1.5vw, 20px);
    border-bottom: 1.5px solid var(--cape-cod);
}

@media screen and (max-width: 750px) {
    .lynxai-rcr-second-title {
        width: clamp(300px, 70vw, 1000px);
        margin: clamp(25px, 2.5vw, 50px) auto 0;
    }

.regulatory-compliance-info-div {
    width:80vw;
    margin: 0 auto;
}

.lynxai-rcr-second-title {
    width: 76vw;
}

.lynxai-rcr-second-icons-div-desktop {
    width: fit-content;
    margin: clamp(24px, 2.4vw, 40px) auto 0 auto;
    grid-template-columns: clamp(200px, 37vw, 460px) clamp(200px, 37vw, 460px);
    grid-template-rows: clamp(140px, 12vw, 250px) clamp(140px, 12vw, 250px);
}

    .regulatory-compliance-div {
        width: 100%;
    }

    .regulatory-compliance-inside-div {
        flex-flow: column;
    }

    .regulatory-compliance-image-div {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        margin: 0 auto;
        height: 40vw;
    }

    .lynxai-rcr-try-demo-div {
        width: clamp(300px, 70vw, 1000px);
    }

    .lynxai-rcr-second-image-1 {
        height: auto;
        width: 45%;
        margin-right: 2%;
    }

    .lynxai-rcr-second-image-2 {
        width: 30%;
        height: auto;
        margin-top: 0;
        margin-left: 2%;
    }
}

@media screen and (max-width: 500px) {
    .lynxai-rcr-second-icons-div-desktop {
        display: none
    }

    .lynxai-rcr-second-icons-div-mobile {
        display: block;
        width: 100vw;
        margin: 20px 0 4vw -20px;
    }

    .lynxai-rcr-second-title-desktop {
        display: none;
    }
    
    .lynxai-rcr-second-title-mobile {
        display: block;
    }
}


@media screen and (max-width: 450px) {
    .lynxai-rcr-second-title {
        width: 100%;
    }

    .lynxai-rcr-try-demo-div {
        width: 100%;
    }

    .lynxai-rcr-second-image-1 {
        width: 52%;
        margin-right: 4%;
    }

    .lynxai-rcr-second-image-2 {
        width: 40%;
        margin-left: 4%;
    }
}