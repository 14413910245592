@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:400|Open+Sans:400,400italic|Poppins:300");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/634ed686d1b273d52761e067.634ed687c718896d28bc1e4b.MBlefda.rcp.png");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --black: #000000;
  --black-2: #0000004c;
  --black-3: #00000059;
  --black-coral-pearl: #57636f;
  --bon-jour: #e2e2e2;
  --cadet-blue: #adb0c2;
  --cape-cod: #41414199;
  --cod-gray: #0a0a0a;
  --ebony-clay: #252849;
  --eerie-black: #1a1a1a;
  --gray-nurse: #eaeaea;
  --gray-nurse-2: #e8e8e857;
  --gray-nurse-3: #e8e8e8cc;
  --gray-nurse-4: #e8e8e899;
  --live-blue: #0071dc;
  --pacific-blue: #15abc8;
  --polar: #e4f1f9;
  --quill-gray: #d5d5d5;
  --shakespeare: #4ea2d5;
  --star-dust: #9f9f9f;
  --vulcan: #151619;
  --lightgray: #f5f5f5;
  --white: #ffffff;
  --white-2: #ffffff59;
}

.font-style-montserrat-family-semibold {
  font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.font-style-montserrat-family-regular {
  font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1px;
}

// FONT SIZE
.extra-extra-small-text {
  font-size: clamp(9px, 0.75vw, 14px);
}

.extra-small-text {
  font-size: clamp(11px, 0.9vw, 16px);
}

.small-text {
  font-size: clamp(13px, 1.1vw, 20px)
}

.medium-text {
  font-size: clamp(14px, 1.5vw, 26px);
}

.large-text {
  font-size: clamp(16px,1.9vw, 32px);
}

.extra-large-text  {
  font-size: clamp(19px, 2.2vw, 40px);
}

.extra-extra-large-text {
  font-size: clamp(21px, 3vw, 70px);
}

// FONT COLOR
.color-black {
  color: var(--eerie-black);
}

.color-white {
  color: var(--white);
}

.color-blue {
  color: var(--live-blue);
}

.color-lightgray {
  color: #757575;
}

// BORDER COLOR
.border-white {
  border-color: var(--white);
}

// BACKGROUND COLOR
.background-blue {
  background-color: var(--live-blue);
}

.blue-button-div {
  margin-top: 3vw;
}

// CAROUSEL
.swiper-pagination-bullet {
  margin: 0 4px;
}

.tablet-mobile-why-choose-lynxai .swiper-pagination-bullet {
  background-color: black !important;
}

.mentors-carousel-main-div .swiper-pagination-bullet {
  background-color: black !important;
}

.main-transparent-carousel-div .swiper-pagination-bullet {
  margin: 0 4px;
  background-color: white;
}

.lynxai-vision-second-info-div .swiper-pagination-bullet {
  background-color: black;
}

.what-is-eu-taxonomy-info-div .swiper-pagination-bullet {
  background-color: black;
}

.regulatory-compliance-div .swiper-pagination-bullet {
  background-color: black !important;
}

.eu-taxonomy-third-block-div .swiper-pagination-bullet {
  background-color: white !important;
}

.swiper-wrapper {
  height: auto;
  width: 100%;
  transition-timing-function: ease-in-out;
}

.swiper-container {
  transition-timing-function: ease-in-out;
  width: 100%;
}

.swiper {
  width: 100%;
  transition-timing-function: ease-in-out;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .blue-button-div {
    margin-top: 4vw;
  }
}

@media screen and (max-width: 450px) {
  .blue-button-div {
    margin-top: 7vw;
  }
}