.buttons-div {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.flow-row {
    width: clamp(230px, 21vw,460px);
    flex-flow: row;
}

.flow-column {
    width: clamp(110px,10vw, 220px);
    margin: 0 auto;
    flex-flow: column;
    height: clamp(95px, 7vw,140px);
}

.flow-row-inverse {
    width: clamp(230px, 21vw,460px);
    flex-flow: row;
}

@media (max-width: 675px) {
    .flow-row-inverse {
        width: clamp(110px,10vw, 220px);
        margin: 0 auto;
        flex-flow: column;
        height: clamp(100px, 7vw,140px);
    }
}
    @media (max-width: 600px) {

    .flow-column {
        width: 240px;
        flex-flow: row;
        height: fit-content;
    }
}