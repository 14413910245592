.three-reasons-main-div {
    margin: max(5vw, 50px) auto;
    max-width: 1500px;
}

.three-reasons-second-reason-text {
    margin-top: max(1.5vw, 12px);
    line-height: max(1.7vw, 15px);
    white-space: pre-line;
}

.desktop-three-reasons-second {
    display: flex;
    justify-content: center;
}

.more-height {
    height: clamp(210px,22vw, 480px)
}

.three-reasons-title {
    line-height: max(4.5vw,40px);
    text-align: center;
}

.normal-height {
    height: max(15vw, 120px);
}

.three-reasons-linkedin-image {
    width: clamp(15px, 1.4vw, 22px);
    height:clamp(15px, 1.4vw, 22px);
}

#message-us-linked-in-button {
    height: max(1.5vw, 15px);
    display:flex;
    margin-top: max(1.5vw, 12px);
    line-height: max(1.5vw, 15px);
    white-space: pre-line;
    color: var(--eerie-black)
}

.three-reasons-linkedin-button {
    background-color: var(--live-blue);
    width: clamp(18px, 1.8vw, 26px);
    height: clamp(18px, 1.8vw, 26px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 6px;
}

.email-us-lynxai-mail:hover {
    text-decoration: underline;
}

#message-us-linked-in-button:hover {
    color: var(--live-blue)
}

.three-reasons-second-reason-div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: max(30vw, 220px);
    padding: max(3vw, 25px);
    margin: 0 auto;
}

.three-reasons-second-reason-icon {
    height: auto;
    object-fit: cover;
    width: clamp(28px, 3vw, 60px);
}

.three-reasons-second-reason-title {
    margin-top: clamp(15px, 3vw,60px);
    line-height: clamp(20px, 2vw, 40px);
}

.desktop-three-reasons-second .reason-1 {
    border-radius: 2vw 0 0 2vw;
}

.desktop-three-reasons-second .reason-2 {
    border-radius: 0;
}

.desktop-three-reasons-second .reason-3 {
    border-radius: 0 2vw 2vw 0;
}

@media screen and (max-width: 675px) {

    .desktop-three-reasons-second {
        flex-flow: column;
    }

    .desktop-three-reasons-second .reason-1 {
        border-radius: 2vw 2vw 0 0;
        background-color: var(--quill-gray);
    }

    .desktop-three-reasons-second .reason-2 {
        background-color: var(--bon-jour);
    }

    .desktop-three-reasons-second .reason-3 {
        background-color: var(--gray-nurse);
        border-radius: 0 0 2vw 2vw;
    }

}