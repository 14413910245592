.main-transparent-carousel-div {
    display:block;
    height: fit-content;
    height: fit-content;
    margin:0 auto;
}

.transparent-carousel-pagination-div-first-carousel {
    text-align: center;
    margin-top: 2vw;
}

.transparent-carousel-pagination-div-second-carousel {
    text-align: center;
    margin-top: 2vw;
}

.transparent-carousel-pagination-div-third-carousel {
  text-align: center;
  margin-top: min(-3vw,-10px);
}

.transparent-carousel-point {
  display: flex;
  flex-flow: column;
  padding: 5vw 4px;
  height:fit-content;
  align-items: flex-start;
}

.transparent-carousel-text {
  margin-top: clamp(15px, 1.5vw, 30px);
  line-height: clamp(15px, 1.5vw, 30px);
}

.border-top-lightgray {
  border-top: 2px solid var(--gray-nurse-2);
}

.border-top-darkgray {
    border-top: 2px solid var(--cape-cod);
  }

.main-transparent-carousel-div .homepage-second-icon {
    height: auto;
    object-fit: cover;
    width: max(36px,7vw);
  }