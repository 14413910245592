.articles-second-main-div {
    display: flex;
    flex-flow: column;
    width: 90vw;
    max-width: 1500px;
    margin: clamp(50px, 5vw, 100px) auto;
}

.articles-second-articles-div {
    margin: 0 0 0 clamp(30px,3vw, 60px);
}

.articles-second-small-article-div {
    display: flex;
    width: 100%;
    height: 100%;
    padding: clamp(25px, 2.5vw, 50px) 0;
    border-bottom: 1px solid #757575;
}

.articles-second-small-article-info-div {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: clamp(10px, 1vw, 20px) clamp(50px, 5vw, 100px) clamp(10px, 1vw, 20px) 0;
    margin-left: clamp(15px,1.5vw, 30px);
}

.articles-second-small-article-background {
    border-radius: clamp(10px,1vw, 20px);
    width: clamp(100px, 15vw, 300px);
    height: clamp(90px, 11vw, 220px);
    object-fit: cover;
    border: 1.5px solid var(--quill-gray);
}

.articles-second-time-article-small-info {
    display: flex;
    height: 27.5%;
    opacity: 0.5;
}

.articles-second-time-small-read-div {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
    height:100%;
}

.articles-second-small-article-title {
    line-height: clamp(15px,1.5vw, 30px);
    white-space: nowrap;
    color: #26b3eb;
    width: 100%;
    height: 27.5%;
    display: flex;
}

.articles-second-small-article-description {
    align-items: center;
    justify-content: left;
    height: fit-content;
    line-height: clamp(12.5px, 1.25vw, 25px);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height:clamp(37.5px, 3.75vw, 70px);
}

.articles-second-time {
    line-height: max(2vw, 10px);
    width: fit-content;
    height: max(2vw, 10px);
    margin: auto 0 auto 0.6vw;
}

.articles-second-clock-icon {
    object-fit: contain;
    width: clamp(12px, 1.2vw, 24px);
}

.articles-second-time-small-read-div .articles-second-time {
    height: max(1.5vw, 10px);
    line-height: max(1.5vw, 10px);
}

@media screen and (max-width: 1250px) {
    .articles-second-small-article-description {
    height:clamp(25px, 2.5vw, 50px);
}
}

@media screen and (max-width: 1000px) {
    .articles-second-main-div {
        width: 90vw;
    }
}

@media screen and (max-width: 750px) {
    .articles-second-most-read-articles-title-text {
    border-bottom: 1px solid #757575;
}
}

@media screen and (max-width: 500px) {
.articles-second-small-article-title {
    white-space: wrap;
}

    .articles-second-small-article-div {
        flex-flow: column;
        justify-content: center;
        align-items: center;
}
.articles-second-most-read-articles-title-text {
    border-bottom: 1px solid #757575;
}
.articles-second-small-article-background {
    width: 300px;
    height: 220px;
}
.articles-second-articles-div {
    margin: 0 auto;
}

.articles-second-small-article-info-div {
    margin: 0;
    width: 250px;
}

.articles-second-small-article-description {
    height: clamp(50px, 5vw, 100px);
    margin: clamp(10px, 1vw, 20px) 0;
}
.articles-second-small-article-title {
    margin-top: clamp(12px, 1.2vw, 24px);
}
}

@media screen and (max-width: 400px) {
    .articles-second-small-article-background {
        width: 270px;
        height: 200px;
    }

    .articles-second-small-article-info-div {
        margin: 0;
        width: 220px;
    }
}

