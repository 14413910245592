.homepage-fourth-block-div {
    margin: clamp(40px, 4vw, 60px) auto;
    background-color: var(--black);
    border-radius: 0px 5vw 0px 5vw;
    display: flex;
    flex-direction: row;
    padding: 8vw;
    flex-wrap: wrap;
    max-width: 1500px;
    justify-content: center;
    align-items: center;
}

.homepage-fourth-main-article {
    height: clamp(250px, 25vw, 500px);
    width: 40%;
}

.homepage-fourth-small-articles {
    height: 100%;
    max-width: 900px;
    width: 60%;
    height: clamp(250px, 25vw, 500px);
    display: flex;
}

.homepage-fourth-small-articles-col {
    width: 50%;
    margin-left: 2vw;
    height: 100%;
    align-items: stretch;
    justify-content: space-between;
    display: flex;
    flex-flow: column;
}

.homepage-fourth-title {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-bottom: clamp(35px, 3.5vw, 70px);
}

.homepage-fourth-main-article-div {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: clamp(10px,1vw, 20px);
    height: clamp(250px, 25vw, 500px);
    position: relative;
    width: 100%;
}

.homepage-fourth-darker-main-article {
    background-color: var(--cod-gray);
    border-radius: clamp(1px,1vw, 20px);
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
}

.homepage-fourth-time-read-div {
    display: flex;
    height: fit-content;
    justify-content: flex-start;
}

.homepage-fourth-time-small-read-div {
    width: fit-content;
    height: clamp(18px,1.8vw, 36px);
    display: flex;
    align-items: flex-end;
    opacity: 0.5;
}

.homepage-fourth-clock-icon {
    object-fit: contain;
    width: clamp(18px,1.8vw, 36px);
}

.homepage-fourth-time-small-read-div .homepage-fourth-time {
    height: clamp(18px,1.8vw, 36px);
    line-height: clamp(18px,1.8vw, 36px);
}

.homepage-fourth-time-small-read-div .homepage-fourth-clock-icon {
    width: clamp(18px,1.8vw, 36px);

}

.homepage-fourth-time {
    line-height: clamp(18px,1.8vw, 36px);
    width: fit-content;
    height: clamp(18px,1.8vw, 36px);
    margin-left: 0.6vw;
}

.homepage-fourth-main-article-text {
    line-height: clamp(20px, 2vw, 40px);
    margin-bottom: clamp(8px, 0.8vw,16px);
}

.main-article-info-div {
    position: absolute;
    bottom: clamp(30px, 3vw, 60px);
    left: clamp(30px, 3vw, 60px);
    width: 50%;
    height: fit-content;
    display: flex;
    flex-flow: column;
}

.homepage-fourth-small-article-div {
    display: flex;
    height: clamp(75px, 7.5vw, 150px);
}

.homepage-fourth-small-article-background {
    border-radius: clamp(10px, 1vw, 20px);
    width: clamp(70px, 7vw, 140px);
    object-fit: cover;
    border: 1.5px solid var(--quill-gray);
}

.homepage-fourth-small-article-info-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    margin-left: clamp(7px, 0.7vw, 14px);
    padding: clamp(5px, 0.5vw, 10px) 0 clamp(3px, 0.3vw, 6px);
}

a {
    text-decoration: none !important;
}

.homepage-fourth-small-article-title {
    line-height: clamp(15px,1.5vw, 30px);
    white-space: wrap;
    color: #26b3eb;
    width: 100%;
}


@media screen and (min-width: 1500px) {
    .homepage-fourth-block-div {
        padding: 5vw;
        width: 88vw;
        max-width: 1500px;
    }
}

@media screen and (min-width: 1300px) {
    .homepage-fourth-main-article {
        width: 45%;
    }

    .homepage-fourth-small-articles {
        width: 55%;
    }
}


@media screen and (max-width: 850px) {
    .homepage-fourth-block-div {
        flex-direction: column;
        border-radius: 0px 8vw 0px 8vw;
        padding: max(10vw, 40px);
    }


    .homepage-fourth-main-article {
        height: fit-content;
        width: 100%;
        margin-top: max(1.5vw, 20px);
    }

    .homepage-fourth-small-articles {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        height: 250px;
        margin-top: clamp(15px, 1.5vw, 30px);
    }

    .homepage-fourth-small-article-title {
        width: 90%
    }

    .homepage-fourth-small-articles-col {
        width: 100%;
        margin-left: 0vw;
    }

    .homepage-fourth-second-col {
        margin-left: 1.5vw;
    }

}

@media screen and (max-width: 500px) {

    .homepage-fourth-small-articles {
        flex-flow: column;
        height: 500px;
    }

    .homepage-fourth-second-col {
        margin-top: 15px;
        margin-left: 0;
    }

}