@import "../../assets/styles/global.scss";

.homepage-second-block-div {
  margin: clamp(50px, 5vw, 100px) auto;
  background-color: var(--black);
  border-radius: 5vw 0px 5vw 0px;
  display: flex;
  flex-direction: column;
  padding: clamp(60px, 6vw, 120px);
  justify-content: center;
  align-items: center;
  max-width: 1500px;
}

.lynxai-rcr-div {
  width: 100%;
}

.lynxai-vision-div {
  width: 100%;
  margin-top: clamp(50px, 5vw, 100px);
}

.lynxai-rcr-inside-div {
  display: flex;
  width: 100%;
  margin-top: 1.5vw;
}

.lynxai-vision-inside-div {
  display: flex;
  margin-top: 1.5vw;
}

.homepage-second-info-div {
  width: 65%;
  display: block;
  height: fit-content
}

.homepage-second-image-div {
  width: 35%;
  position: relative;
}

.lynxai-rcr-div .homepage-second-image-div {
  text-align: right;
  padding: clamp(20px, 2vw, 40px) 0 0 clamp(50px, 5vw, 100px);
}

.lynxai-vision-div .homepage-second-image-div {
  text-align: left;
  padding: clamp(20px, 2vw, 40px) clamp(50px, 5vw, 100px) 0 0;
}

.homepage-second-title {
  text-align: left;
  white-space: nowrap;
}

.homepage-second-description {
  margin: clamp(10px, 1vw, 20px) 5vw 0 0;
  line-height: clamp(15px, 1.5vw, 30px);
}

.image-diagram {
  height: auto;
  background-size: scale;
  width: 100%;
}

lynxai-vision-div .image-diagram {
  position: absolute;
  left: 0;
  top: 0;
}


.image-diagram-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.homepage-second-icons-div-desktop {
  display: grid;
  height: fit-content;
  width: 100%;
  grid-template-columns: 45% 45%;
  grid-template-rows: clamp(90px, 9vw, 180px) clamp(90px, 9vw, 180px);
  grid-column-gap: clamp(30px, 3vw, 60px);
  grid-row-gap: 2vw;
  margin-top: clamp(20px, 2vw, 40px);
}

.homepage-second-icons-div-mobile {
  display: none;
  height: fit-content;
  margin: clamp(35px, 3.5vw, 70px) auto clamp(60px, 6vw, 120px) auto;
  width: calc(100vw - 40px);
  margin-left: clamp(-60px, -6vw, -120px);
}

.homepage-second-points {
  display: flex;
  flex-flow: column;
  border-top: 2px solid var(--gray-nurse-2);
  padding: clamp(10px, 1vw, 20px) clamp(20px, 2vw, 40px) 0 0;
}

.homepage-second-icon {
  height: auto;
  object-fit: cover;
  width: clamp(25px, 2.5vw, 50px);
}

.homepage-second-text {
  margin-top: clamp(15px, 1.5vw, 30px);
  line-height: clamp(15px, 1.5vw, 30px);
  text-align: center;
}

.homepage-second-text-desktop {
  margin-top: clamp(15px, 1.5vw, 30px);
  line-height: clamp(15px, 1.5vw, 30px);
}

.homepage-second-info-div .button-div {
  margin-top: clamp(30px, 3vw,60px);
  justify-content: space-between;
  display: flex;
  width: clamp(230px,21vw, 450px);
}

.homepage-second-main-title-desktop {
  display: block;
}

.homepage-second-main-title-mobile {
  display: none;
}

@media screen and (max-width: 750px) {

  .lynxai-rcr-inside-div {
    flex-flow: column;
    flex-direction: column-reverse;
  }

  .lynxai-vision-inside-div {
    flex-flow: column;
  }

  .homepage-second-image-div {
    width: 100%;
    padding: 0;
  }

  .lynxai-rcr-div .homepage-second-image-div,
  .lynxai-vision-div .homepage-second-image-div {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }

  .image-diagram {
    width: 55vw;
  }

  .homepage-second-info-div {
    width: 100%;
  }

  .homepage-second-block-div {
    border-radius: 8vw 0px 8vw 0px;
  }

  .homepage-second-main-title-desktop {
    display: none;
  }

  .homepage-second-main-title-mobile {
    display: block;
  }

  
}

@media screen and (max-width: 500px) {
  .homepage-second-icons-div-desktop {
    display: none
  }

  .homepage-second-icons-div-mobile {
    display: block;
  }
}
