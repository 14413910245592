.map-div {
    height: 55vw;
    max-width: 1600px;
    border-radius: 10px;
    margin: max(5vw,50px) auto;
}

#gmap_canvas-desktop {
    border-radius: 10px;
    border:0;
    height: 100%;
    width: 100%;
    display: block;
}

#gmap_canvas-tablet {
    border-radius: 10px;
    border:0;
    height: 100%;
    width: 100%;
    display: none;
}

#gmap_canvas-mobile {
    border-radius: 10px;
    border:0;
    height: 100%;
    width: 100%;
    display: none;
}

@media screen and (min-width: 900px) {
    .map-div {
    height: 40vw;
    margin: max(5vw,50px) auto;
    }
}

@media screen and (max-width: 1200px) {
    #gmap_canvas-desktop {
        display: none;
    }
    
    #gmap_canvas-tablet {
        display: block;
    }
    
    #gmap_canvas-mobile {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    #gmap_canvas-desktop {
        display: none;
    }
    
    #gmap_canvas-tablet {
        display: none;
    }
    
    #gmap_canvas-mobile {
        display: block;
    }

    .map-div {
        height: max(55vw,350px);
}
}