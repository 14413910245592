#scroll-to-top-button {
    position: fixed;
    padding: 7px 14px;
    font-size: clamp(14px, 1.2vw, 20px);
    bottom: clamp(20px, 2.5vw, 40px);
    right: clamp(20px, 2.5vw, 40px);
    border-radius: clamp(5px, 0.5vw, 10px);
    border: clamp(1px, 1vw, 2px) solid var(--star-dust);
    background-color: transparent;
    color: var(--star-dust);
    text-align: center;
    z-index: 9999;
    transition: all 0.3s ease-in-out;
    mix-blend-mode: difference;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 800px) {
    #scroll-to-top-button:hover {
        background-color: var(--live-blue);
        color: white;
        border: clamp(1px, 1vw, 3px) solid var(--live-blue);
        padding: 10px 20px;
        font-size: clamp(12px, 1.2vw, 24px);
        bottom: clamp(20px, 2vw, 40px);
        right: clamp(20px, 2vw, 40px);
        mix-blend-mode: normal;
    }
}

@media screen and (max-width: 800px) {
    #scroll-to-top-button {
        background-color: var(--live-blue);
        color: white;
        border: clamp(1px, 1vw, 3px) solid var(--live-blue);
        mix-blend-mode: normal;
    }

}