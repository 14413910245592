.transparent-button {
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 6px;
  text-align: center;
  white-space: nowrap;
  width: clamp(110px,10vw, 220px);
  height: clamp(40px, 3vw,60px);
  line-height: clamp(40px, 3vw,60px);
  transition: all 0.3s;
}

.transparent-button:hover {
  background-color: lightgray;
  color:white
}

.footer:hover {
  background-color: gray;
}

@media (max-width: 850px) {

  .footer {
    width: 110px;
    }
}