.get-in-touch-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: max(2.5vw,20px) auto 0 auto;
    max-width: 1600px;
    width: 90vw;
}

.contact-us-first-text-div {
    margin-right: clamp(20px, 2vw, 40px);
    width: 45vw;
    max-width: 750px;
}

.contact-us-first-form-div {
    width: clamp(300px, 37vw, 740px);
    border-radius: 0px clamp(30px, 3vw, 60px) 0px clamp(30px, 3vw, 60px);
    background-color: var(--black);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: clamp(30px, 2vw,40px);
    height:fit-content;
    width: 45vw;
    max-width: 750px;
}

.contact-us-first-main-text {
    margin-top: clamp(12px, 1.2vw,24px);
    line-height: clamp(15px, 1.7vw, 34px);
}

.contact-us-first-title {
    margin-top: 2vw;
}

.form-div {
    text-align: center;
    padding: 0;
}

.half-input {
    width: 49%;
    height: clamp(24px,2.4vw, 48px);
    color: var(--cape-cod);
    border-radius: 4px;
    border: none;
    padding-left: 8px;
  box-sizing: border-box;
  outline: none;
}


.full-input {
    margin: clamp(7px,0.7vw,14px) 0;
    height: clamp(24px,2.4vw, 48px);
    color: var(--cape-cod);
    border-radius: 4px;
    border: none;
    padding-left: 8px;
    outline: none;
}

.half-input:focus, .full-input:focus {
    outline: 1.5px solid var(--live-blue);
}

.full-input-div {
    display:flex;
    flex-flow: column;
    width: 100%;;
}

.half-input-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: clamp(7px,0.7vw,14px) 0;
}

.submit-button-form:enabled {
    width: 100%;
    margin: 0.6vw 0;
    background-color: var(--live-blue);
    height: clamp(24px, 2.4vw, 48px);
    border-radius: 6px;
    border: none;
}

.required-field-label {
    margin-right: auto;
}

.submit-button-form:hover {
    background-color: #0b4261;
    color:white;
}

#message-input {
    height: clamp(100px, 1vw, 200px);
    padding-top: 10px
}

@media screen and (max-width: 1100px) {
    .half-input {
        width: 46%;
}
}

@media screen and (max-width: 750px) {
    .half-input {
        width: 45%;
}
}

    @media screen and (max-width: 675px) {
.get-in-touch-div {
    flex-flow: column;
    margin-top:0;

}

.half-input {
    width: 47%;
}

.contact-us-first-text-div {
    width: 80vw;
    margin-right: 0;
}

.contact-us-first-form-div {
    width: 80vw;
    max-width: 500px;
    margin-top: clamp(20px, 2vw, 40px);
}


}

@media screen and (max-width: 575px) {
    .half-input {
        width: 46%;
    }
}

@media screen and (max-width: 450px) {
    .contact-us-first-text-div {
        width: 85vw;
    }
    }

@media screen and (max-width: 400px) {
    .half-input {
        width: 45%;
    }
    }