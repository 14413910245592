.team-member-details {
    display: flex;
    flex-flow: row;
    width: clamp(500px, 35vw, 550px);
    margin: clamp(20px, 2vw, 40px) clamp(25px, 2.5vw, 50px);
}

.team-member-img {
    width: 220px;
    height: 250px;
    object-fit: cover;
    border-radius: 1vw;
}

.team-member-text-div {
    padding: 20px 0 0 25px;
    height: 100%;
}

.team-member-name-linkedin {
    display: flex;
    align-items: center;
}

.about-us-fourth-team-member-experience {
    margin-top: clamp(10px, 0.8vw, 15px)
}

.about-us-fourth-linkedin-button {
    background-color: var(--live-blue);
    width: clamp(20px, 1.8vw, 30px);
    height: clamp(20px, 1.8vw, 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
}

.about-us-fourth-linkedin-image {
    width: clamp(18px, 1.5vw, 25px);
    height:clamp(18px, 1.5vw, 25px);
}

.about-us-fourth-experience-div {
    display: flex;
    align-items: top;
    margin: 9px 0;
}

.about-us-fourth-experience-text {
    height: fit-content;
    width: 100%;
    line-height: clamp(12px, 1.2vw, 24px);
}

.about-us-fourth-experience-bullet {
    width: clamp(14px,1.4vw, 24px);
    height: clamp(14px,1.4vw, 24px);
    margin-right: 5px;
}

.about-us-fourth-experience-main-div {
    margin-top: clamp(20px, 3vw, 30px);
}

@media screen and (max-width: 650px) {
    .team-member-details {
        flex-flow: column;
        width: fit-content;
    }

    .team-member-text-div {
        padding-left: 0;
        width:220px;
    }

    .about-us-fourth-experience-main-div {
        margin-top: clamp(5px, 0.5vw, 10px);
    }
}