.blue-button {
  background-color: var(--live-blue);
  border-radius: 6px;
  white-space: nowrap;
  text-align: center;
  height: clamp(40px, 3vw,60px);
  line-height: clamp(40px, 3vw,60px);
  transition: all 0.3s;
}

.blue-button:hover {
  background-color: #0b4261;
  color:white
}

.fit-content {
  width: fit-content;
  padding: 0 2vw;
}

.fixed-width {
  width: clamp(110px,10vw, 220px);
}

@media (max-width: 1023px) {
  .fit-content {
    padding: 0 10px
  }
}

@media (max-width: 850px) {

  .footer {
    width: 110px;
    }
}

