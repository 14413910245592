.main-transparent-three-rows-carousel-div {
  width: 100%;
  display: block;
  height: fit-content;
}

.transparent-three-rows-carousel-pagination-div-first-carousel {
  text-align: center;
  margin-top: 2vw;
}

.eu-taxonomy-third-block-div .transparent-three-rows-carousel-pagination-div-first-carousel {
  text-align: center;
  margin-top: 0;
}

.transparent-three-rows-carousel-point {
  display: flex;
  flex-flow: column;
  border-top: 2px solid var(--cape-cod);
  padding: 5vw 4px;
  height: fit-content;
  align-items: flex-start;
}

.border-top-lightgray {
  border-top: 2px solid var(--gray-nurse-2);
}

.border-top-darkgray {
    border-top: 2px solid var(--cape-cod);
  }


.main-transparent-three-rows-carousel-div .homepage-second-icon {
  height: auto;
  object-fit: cover;
  width: max(36px, 7vw);
}

.main-transparent-three-rows-carousel-div .homepage-second-text {
  margin-top: 20px;
  text-align: center;
}

.main-transparent-three-rows-carousel-div .homepage-second-icon {
  margin-bottom: max(16px, 3vw);
}

.main-transparent-three-rows-carousel-div .medium-text {
  line-height: 15px;
}

.main-transparent-three-rows-carousel-div .small-text {
  line-height: 15px;
}