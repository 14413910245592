@import "../../assets/styles/global.scss";

.block-div {
    margin: clamp(40px, 4vw, 100px) 0 clamp(40px, 5vw, 80px);
}

.sentence-buttons-div {
    width: max(300px, 50vw);
    margin: 0 30vw 0 20vw;
    height: clamp(170px, 17vw, 300px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.buttons-homepage-div {
    margin-top: 2vw;
    flex-flow: row;
    justify-content: flex-start;
}

.sentence-paragraph {
    line-height: clamp(40px,4vw, 80px);
}

.homepage-images-div {
    justify-content: center;
    display: flex;
    margin: clamp(50px, 4vw,160px) auto clamp(80px, 8vw,160px);
}

#homepage-img-1 {
    border-radius: clamp(16px, 1.6vw,32px);
    display: block;
    margin: 0 1vw 0 -5vw;
    height: clamp(270px, 27vw, 540px);
    width: auto;
}

#homepage-img-2 {
    border-radius: clamp(16px, 1.6vw,32px);
    display: block;
    margin: 15% 1vw 0 1vw;
    height: clamp(200px, 20vw, 400px);
    width: auto;
}

#homepage-img-3 {
    border-radius: clamp(16px, 1.6vw,32px);
    display: block;
    margin: 1% 1vw 0 1vw;
    height: clamp(230px, 23vw, 460px);
    width: auto;
}

#homepage-img-4 {
    border-radius: clamp(16px, 1.6vw,32px);
    display: block;
    margin: 6% -5vw 0 1vw;
    height: clamp(240px, 24vw, 480px);
    width: auto;
}

.brands-partners-div {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

@media screen and (min-width: 1550px) {
    .homepage-images-div {
        width: 1500px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (max-width: 1100px) {
    .sentence-buttons-div {
        height: max(16vw, 130px);
    }
}

@media screen and (max-width: 850px) {
    .homepage-images-div {
    margin: clamp(100px, 10vw,200px) auto clamp(120px, 12vw,240px);
}
}

@media screen and (max-width: 700px) {

    #homepage-img-1 {
        display: block;
        margin: 0 1vw 0 -10vw;
        height: clamp(225px, 22.5vw, 450px);
    }

    #homepage-img-2 {
        display: block;
        margin-top: 40%;
        height:clamp(150px, 15vw, 300px);
    }

    #homepage-img-3 {
        display: none;
    }

    #homepage-img-4 {
        display: block;
        margin: 5% -10vw 0 1vw;
        height: clamp(200px, 20vw, 400px);
    }

    .sentence-buttons-div {
        width: max(320px, 65vw);
        margin: 0 20vw 0 15vw;
    }
}

@media screen and (max-width: 500px) {
    .sentence-buttons-div {
        width: 85%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px) {
    .sentence-buttons-div {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .sentence-buttons-div {
        height: 160px;
    }
}