.citation-div {
    margin: clamp(60px, 6vw, 120px) auto;
    display:flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: max(4vw,30px) clamp(30px,3vw,60px);
    background-color: var(--gray-nurse);
    border-radius: clamp(16px, 1.6vw,32px) ;
    max-width: 1500px;
}

.citation-text {
    width: 100%;
    text-align: center;
    margin: max(3vw,25px) 0;
    line-height: clamp(25px, 3vw,50px);
}

.citation-image {
    width:max(3vw,20px);
    height: auto;
}
