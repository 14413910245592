.articles-first-main-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: clamp(25px, 2.5vw, 50px) auto;
    width: 90vw;
    max-width: 1500px;
}

.articles-first-small-articles {
    height: 100%;
    width: 60%;
    max-width: 900px;
    display: flex;
}

.articles-first-main-article-div {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: max(1vw, 8px);
    height: clamp(250px, 25vw, 500px);
    position: relative;
    width: 100%;
}

.articles-first-block-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1500px;
    height: clamp(250px, 25vw, 500px);
    justify-content: center;
}

.articles-first-most-read-articles-title-div {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
}

.articles-first-most-read-articles-title-text {
    margin: 0 clamp(40px, 4vw, 80px);
    line-height: clamp(40px, 4vw, 80px);
    text-align: center;
}

.articles-first-line-divider {
    background-color: #757575;
    height: 1px;
    flex-grow: 100;
}

.articles-first-main-article {
    height: clamp(250px, 25vw, 500px);
    width: 40%;
    max-width: 600px;
}

@media screen and (max-width: 1500px) {
    .articles-first-most-read-articles-title-div {
        margin-bottom: clamp(10px, 1vw, 20px);
    }
}

@media screen and (max-width: 850px) {
    .articles-first-block-div {
        flex-direction: column;
        height: fit-content;
        align-items: center;
        width: clamp(475px, 60vw, 800px);
    }

    .articles-first-small-articles {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        height: 250px;
        margin-top: clamp(15px, 1.5vw, 30px);
    }

    .articles-first-main-article {
        width: 100%;
        height: 100%;
    }

    .articles-first-most-read-articles-title-div {
        margin-bottom: 0;
    }

    .articles-first-line-divider {
        display: none;
    }

    .articles-first-most-read-articles-title-text {
        border-bottom: 1px solid #757575;
        margin-bottom: clamp(10px, 1vw, 20px);
    }
}

@media screen and (max-width: 750px) {
    .articles-first-block-div {
        width: clamp(300px, 80vw, 800px);
    }
}

@media screen and (max-width: 475px) {
.articles-first-small-articles {
    flex-flow: column;
    height: 500px;
}
}
