@import "../../assets/styles/global.scss";

.header-div {
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    display: flex;
    height: clamp(90px, 5vw, 100px);
    border-bottom: 2px solid #cccccc;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
}

.right-button {
    margin-right: clamp(30px, 3vw, 60px);
}

.navbar-link {
    cursor: pointer;
    white-space: nowrap;
    line-height: 60px;
    padding: 0 clamp(15px, 1.5vw, 35px);
}

.navbar-link:hover {
    color: var(--live-blue)
}

.menu-desktop {
    display: flex;
    margin: 0 auto;
}

.hamburguer {
    display: none;
}

.icon-div {
    display: flex;
    height: clamp(35px, 2.5vw, 45px);
    width: clamp(135px, 11vw, 200px);
    margin-left: clamp(30px, 3vw, 60px);
    justify-content: space-between;
}

.name-img {
    margin: auto;
    height: clamp(20px, 2vw, 30px);
    width: auto;
}

#overlay {
    overflow: hidden;
    position: fixed;
    background-color: black;
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    transition: left linear 500ms;
    display: none;
}

.div-lynxai-rcr {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    position: relative;
    align-items: center;
    z-index: 999;
}

.dropdown-div {
    display: none;
    position: absolute;
    height: fit-content;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    left: 50%;
    border: 1px solid var(--live-blue);
    top: 60px;
    background-color: var(--lightgray);
    border-radius: 6px;
    width: clamp(120px, 11vw, 170px);
    box-shadow: 6px 6px 24px #0000000d;

    hr {
        border: 0.5px solid var(--cape-cod);
        margin: 0;
    }
}

.dropdown-button {
    padding: clamp(12px, 1.2vw, 24px) clamp(8px, 0.8vw, 16px);
    text-align: left;
    width: clamp(120px, 11vw, 170px);
  box-sizing: border-box;
  color: var(--cod-gray);
    border-radius: 6px;
    height: fit-content;
    transition: 0.3s all ease-in-out;
}

.dropdown-button:hover {
  color: var(--live-blue);
}

.div-lynxai-rcr:hover .dropdown-div {
    display: block;
}

.sub-item-text {
    margin-left: 15px;
}

#eu-taxonomy {
    display: block;
    background-color: var(--lightgray);
}

#icon-down-rcr {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 20px;
}

@media screen and (min-width: 1500px) {

    .icon-div {
        margin-left: 7vw;
    }


    .right-button {
        margin-right: 7vw;
    }

}

@media screen and (min-width: 1700px) {

    .icon-div {
        margin-left: 15vw;
    }


    .right-button {
        margin-right: 15vw;
    }

}

@media screen and (max-width: 800px) {

    .menu-desktop {
        display: none;
    }

    .right-button {
        display: none;
    }

    .header-div {
        background-color: transparent;
        border-bottom: none;
    }
}