.pai-second-div {
    height:fit-content;
    margin: max(5vw,50px) auto;
    padding:2vw 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: clamp(250px,70vw,1700px);
}

.pai-second-description {
    text-align: center;
    margin-top: clamp(15px, 1.5vw, 30px);
}