.about-us-fourth-main-div {
    border-radius: 0px 5vw 0px 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: clamp(50px, 5vw, 100px) auto;
    background-color: var(--black);
    padding: clamp(60px, 6vw, 120px);
    max-width: 1500px;
}

.about-us-fourth-team-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 3vw 0;
}

@media screen and (max-width: 750px) {
    .about-us-fourth-main-div {
        border-radius: 0px 8vw 0px 8vw;
        padding: 10vw;
    }
}