.homepage-third-reason-div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: clamp(160px,16vw, 320px);
    width: clamp(300px,30vw,600px);
    padding: clamp(30px,3vw,60px);
    background-color: pink;
}

.homepage-third-reason-icon {
    height: auto;
    object-fit: cover;
    width: max(3vw,28px);
}

.reason-1 {
    background-color: var(--quill-gray);
    border-radius: 1vw 0px 0px 1vw;
}

.desktop-why-choose-lynxai {
    display: flex;
    justify-content: center;
}

.tablet-mobile-why-choose-lynxai {
    display: none;
}

.reason-2 {
    background-color: var(--bon-jour);
    border-radius: 0px;
}

.reason-3 {
    background-color: var(--gray-nurse);
    border-radius: 0px 1vw 1vw 0px;
}

.homepage-third-reason-title {
    margin-top: 3vw;
}

.homepage-third-reason-text {
    margin-top: 1.5vw;
}

.gray-carousel-pagination-div {
    text-align: center;
    margin-top: 1vw;
}

@media screen and (max-width: 675px) {

    .reason-1,
    .reason-2,
    .reason-3 {
        background-color: var(--quill-gray);
    }

    .homepage-third-reason-div {
        border-radius: 12px;
        width:fit-content;
        height: clamp(150px,18vw, 350px);
    }

    .tablet-mobile-why-choose-lynxai {
        display: block;
        height: fit-content;
        min-height: clamp(230px,23vw, 460px);
        width: 100vw;
        position: absolute;
        left:-20px;
        top:40px;
    }

    .desktop-why-choose-lynxai {
        display: none;
    }

    .homepage-third-reason-title {
        margin-top: 5vw;
    }

    .homepage-third-reason-text {
        margin-top: 2.5vw;
    }

}

@media screen and (max-width: 475px) {

    .homepage-third-reason-title {
        margin-top: 7vw;
    }

    .homepage-third-reason-text {
        margin-top: 3vw;
    }
}