.about-us-fifth-main-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    margin: clamp(50px, 3vw, 80px) auto;
}

.about-us-fifth-mentors-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 3vw 0;
    width: clamp(250px, 95vw, 1800px);
}

.about-us-fifth-mentors-div-mobile {
    display: none;
}

.about-us-fifth-mentors-div-desktop {
    display: flex;
}

@media screen and (max-width: 850px) {
    .about-us-fifth-mentors-div {
        flex-flow: column;
    }
    .about-us-fifth-mentors-div-mobile {
        display: flex;
    }

    .about-us-fifth-mentors-div-desktop {
        display: none;
    }
}