.submitted-form-div {
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin: clamp(60px, 6vw, 120px) auto;
}

.submitted-form-div > * {
    margin: 1vw 0;
  }

  .submitted-form-text-div > * {
    margin: clamp(7px, 0.7vw, 14px);
  }
  