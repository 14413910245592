.homepage-fifth-div {
    display: flex;
    margin: 10vw auto;
    width: 60vw;
    max-width: 1500px;
    justify-content: center;
    align-items: center;
}

.esg-needs-diagram {
    width: 24vw;
    height: auto;
    min-width: 180px;
}

.homepage-fifth-diagram-text {
    line-height: 3.5vw;
}

.homepage-fifth-dashboard-text {
    display: flex;
    align-items: flex-start;
    width: 50%;
    flex-direction: column;
    padding: 4vw 0;
}

@media screen and (max-width: 1100px) {
    .homepage-fifth-div {
        width: 70vw;
    }

    .esg-needs-diagram {
        width: 28vw;
        height: auto;
        min-width: 180px;
    }
}

@media screen and (max-width: 800px) {
    .homepage-fifth-div {
        width: 75vw;
    }
}

@media screen and (max-width: 700px) {
    .homepage-fifth-div {
        width: 80vw;
    }

    .homepage-fifth-diagram-text {
        line-height: 7vw;
    }
}

@media screen and (max-width: 450px) {
    .homepage-fifth-div {
        flex-flow: column;
        flex-direction: column-reverse;
        align-items: center;
        margin-top:15vw;
    }

    .homepage-fifth-dashboard-text {
        width: 100%;
        align-items: center;
        padding: 6vw 0;
    }

    .homepage-fifth-diagram-text {
        margin-bottom: 2vw;
    }
}