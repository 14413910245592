.round-corners-main-image-block-content {
    left: clamp(50px, 5vw,100px);
    position: absolute;
    bottom: clamp(60px, 6vw,120px);
    height: fit-content;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.round-corners-main-image-main-text {
    height: fit-content;
    line-height: clamp(25px, 3.5vw, 60px);
    width: clamp(350px, 35vw, 700px);
    margin: clamp(10px, 1.5vw, 35px) 0;
}

.hide-buttons-div {
    display:none;
}

@media screen and (max-width: 500px) {
.round-corners-main-image-main-text {
    width: 65vw;
}
}