.what-is-eu-taxonomy-div {
    margin: clamp(80px, 8vw, 160px) auto clamp(50px, 5vw, 100px);
    width: 85vw;
    max-width: 1500px;
}

.what-is-eu-taxonomy-inside-div {
    display: flex;
}

.what-is-eu-taxonomy-image-div {
    position: relative;
    text-align: right;
    margin-right: clamp(20px, 2vw, 40px);
    width: clamp(250px, 25vw, 500px);
    max-width: 600px;
}

.what-is-eu-taxonomy-div .image-diagram {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
}

.eu-taxonomy-second-icon {
    height: auto;
    object-fit: cover;
    width: clamp(25px, 2vw, 45px);
    margin-bottom: clamp(3px, 0.3vw, 9px)
}

.eu-taxonomy-second-points {
    border-top: 2px solid var(--cape-cod);
    display: flex;
    flex-flow: column;
    padding: clamp(14px, 1.4vw, 30px) clamp(10px, 1vw, 25px) 0 0;
}

.eu-taxonomy-second-third-point-desktop-last {
    border-top: none;
}

.what-is-eu-taxonomy-info-div {
    display: block;
    width: 100%;
    height: fit-content;
}

.eu-taxonomy-second-image-1 {
    height: auto;
    border-radius: clamp(12px, 1.2vw, 24px);
    object-fit: cover;
    width: clamp(150px, 18vw, 300px);
}

.eu-taxonomy-second-image-2 {
    border-radius: clamp(12px, 1.2vw, 24px);
    object-fit: cover;
    width: clamp(120px, 12vw, 260px);
    height: auto;
    margin-top: clamp(20px, 2vw, 40px);
}

.eu-taxonomy-second-icons-div-mobile {
    display: none;
    width: 100vw;
    height: fit-content;
}

.eu-taxonomy-second-title {
    width: 50vw;
    text-align: left;
    line-height: clamp(25px, 4vw, 60px);
}

.eu-taxonomy-second-text {
    line-height: max(1.5vw, 15px);
    margin-top: max(0.7vw, 7px);
}

.eu-taxonomy-second-icons-div-desktop {
    display: grid;
    height: fit-content;
    width: 100%;
    max-width: 950px;
    grid-template-columns: clamp(200px, 28vw, 460px) clamp(200px, 28vw, 460px);
    grid-template-rows: fit-content;
    grid-column-gap: clamp(20px, 2vw, 30px);
    margin-top: clamp(24px, 2.4vw, 40px);
    padding-bottom: clamp(15px, 1.5vw, 20px);
    border-bottom: 1.5px solid var(--cape-cod);
}

.eu-taxonomy-second-third-point-desktop {
    display: flex;
}

.eu-taxonomy-second-title-desktop {
    display:block
}

.eu-taxonomy-second-title-mobile {
    display:none
}

@media screen and (max-width: 750px) {

    .what-is-eu-taxonomy-inside-div {
        flex-flow: column;
    }

    .what-is-eu-taxonomy-image-div {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 40vw;
    }

    .eu-taxonomy-second-title {
        width: 76vw;
        margin: 0 auto;
    }
  
    .eu-taxonomy-second-image-1 {
        height: auto;
        width: 45%;
        margin-right: 2%;
    }

    .eu-taxonomy-second-image-2 {
        width: 30%;
        height: auto;
        margin-top: 0;
        margin-left: 2%;
    }

    .what-is-eu-taxonomy-info-div {
        width: 80vw;
        margin: clamp(24px, 2.4vw, 40px) auto 0 auto;
    }

    .eu-taxonomy-second-icons-div-desktop {
        width: fit-content;
        grid-column-gap: clamp(20px, 2vw, 30px);
    margin: clamp(24px, 2.4vw, 40px) auto 0 auto;
    grid-template-columns: clamp(200px, 37vw, 460px) clamp(200px, 37vw, 460px);
    grid-template-rows: clamp(140px, 12vw, 250px);
    }

    .eu-taxonomy-second-third-point-desktop {
        width: 76vw; 
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px) {
    .eu-taxonomy-second-title {
        width: 100%;
        text-align: center;
        margin: clamp(25px, 2.5vw, 50px) auto 0;
    }

    .eu-taxonomy-second-icons-div-desktop {
        display: none
    }

    .eu-taxonomy-second-third-point-desktop {
        display: none
    }
  .eu-taxonomy-second-icons-div-mobile {
        display: block;
        margin: 20px 0 4vw calc(-7.5vw);
    }

    .eu-taxonomy-second-title-desktop {
        display:none
    }
    
    .eu-taxonomy-second-title-mobile {
        display:block
    }

}

    @media screen and (max-width: 450px) {
    .lynxai-rcr-second-title {
        width: 100%;
    }

    .lynxai-rcr-second-image-1 {
        width: 52%;
        margin-right: 4%;
    }

    .lynxai-rcr-second-image-2 {
        width: 40%;
        margin-left: 4%;
    }
}