.error-404-div {
    background-color: black;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    padding: clamp(50px, 5vw, 100px)
}

#alert-icon {
    width: clamp(70px, 7vw, 140px);
    height: auto;
}

#image-404 {
    height: auto;
    width: clamp(120px, 12vw, 240px);
    margin: clamp(20px, 2vw,40px) 0 clamp(40px, 4vw,80px) 0;
}