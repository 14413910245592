.homepage-third-second-row {
    width: 100%;
    max-width: 1500px;
    height: fit-content;
    display: flex;
    margin: 7vw auto;
    flex-flow: column;
    justify-content: flex-start;
}

.why-choose-lynxai {
    height: fit-content;
    text-align: center;
    margin-bottom: 2vw;
}

@media screen and (max-width: 675px) {

    .homepage-third-second-row {
        height:280px;
        position: relative;
    }

    .why-choose-lynxai {
        margin-bottom: 4vw;
    }
}

@media screen and (max-width: 475px) {


    .why-choose-lynxai {
        margin-bottom: 4.5vw;
    }
}