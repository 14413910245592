.request-demo-div {
    display: flex;
    justify-content: center;
    align-items: top;
    margin: max(2.5vw,20px) auto 0 auto;
    max-width: 1600px;
    width: 90vw;
}

.request-demo-first-text-div {
    margin-right: clamp(20px, 2vw, 40px);
    width: 45vw;
    max-width: 750px;
    text-align: right;
    padding: clamp(20px,2vw,40px) clamp(40px,4vw,80px) 0 0;
}

@media screen and (max-width: 675px) {
    .request-demo-div {
        flex-flow: column;
        align-items: center;
        margin-top:0;
    }

    .request-demo-first-text-div {
        width: 80vw;
        margin-left: 0;
    text-align: left;
    padding: 0;
    }
}