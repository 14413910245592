.mentors-carousel-pagination-div {
    text-align: center;
    margin-top: 1vw;
}

.mentors-carousel-main-div {
    display: flex;
    flex-flow: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-top: 15px;
}

.mentor-details-carousel {
    display: flex;
    flex-flow: column;
    width: clamp(250px, 25vw, 500px);
    margin: 0 auto;
    background-color: var(--polar);
    border-radius: 16px;
    padding: clamp(20px, 2vw, 30px);
    height: clamp(450px, 40vw, 550px);
}

.mentor-img-carousel {
    width: clamp(230px, 23vw, 330px);
    height: auto;
    object-fit: cover;
    border-radius: 1vw;
    margin:0 auto;
}

.mentor-text-div-carousel {
    padding: 20px 0 0 10px;
    height: 100%;
}

.about-us-fifth-experience-main-div-carousel {
    margin-top: clamp(20px, 3vw, 30px);
}

.about-us-fifth-experience-text-carousel {
    line-height: clamp(14px, 1.4vw, 28px);
}

.about-us-fifth-experience-div-carousel {
    display: flex;
    align-items: top;
    margin: 9px 0;
}

.about-us-fifth-experience-bullet-carousel {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}